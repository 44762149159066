$primary-dark-color: #25255B;
$primary-light-color: #3a71b5;
$primary-warm-color: #F05864;
$nav-icon-color: #757575;

$primary-background-color: #F8F8F8;
$primary-nav-background-color: #FFFFFF;

$column-container-border-color: rgb(188, 188, 188);
$column-container-border-width: 1px;

$card-container-border-color: rgb(188, 188, 188);
$card-container-border-width: 1px;

$title-font-size: 16px;
$subtitle-font-size: 14px;
$text-font-size: 12px;
$small-text-font-size: 10px;
$grey-text: #666666;

.gray {
    color: $grey-text; }

.dark-gray {
    color: #454545; }

.medium-text {
    font-size: $text-font-size; }

.wordCount {
    color: #757575;
    margin-left: auto;
    display: table;
    font-size: 13px; }

.lightCardContainer {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(35, 43, 51, 0.16) 0px 1px 3px 0px;
    width: 100%;
    border: 0px solid rgb(220, 225, 230); }

%center-rail-title {
    font-size: $title-font-size;
    color: black;
    font-weight: bold;
    margin-top: 5px; }

%cardContainer {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(35, 43, 51, 0.16) 0px 1px 3px 0px;
    width: 100%;
    border: 0px solid rgb(220, 225, 230); }

%columnContainer {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(35, 43, 51, 0.16) 0px 1px 3px 0px;
    width: 100%;
    border: 0px solid rgb(220, 225, 230); }

%adminTabContainer {
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    background-color: white;
    width: 100%;
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

%adminTransparentTabContainer {
    border: 0px solid #bcbcbc;
    background-color: #f7f7f7;
    width: 100%;
    box-shadow: 0 0px 0px 0px rgba(0,0,0,.2);
    @extend %horizontallyCentered; }

%columnContainerLeft {
    @extend %columnContainer; }

%columnContainerRight {
    margin-right: auto!important;
    @extend %columnContainer; }

%columnContainerCenter {
    margin-top: 25px; }

%verticallyCentered {
    margin-top: auto!important;
    margin-bottom: auto!important; }

%horizontallyCentered {
    margin-left: auto!important;
    margin-right: auto!important; }

%pageNavContainer {
    min-width: 1000px!important;
    max-width: 1228px!important;
    @extend %horizontallyCentered; }

%pageContainer {
    width: 1228px;
    padding-top: 50px!important;
    @extend %horizontallyCentered; }

%centerRail {
    margin-top: 25px; }

.mobileCenterReail {
    max-width: 100%!important;
    margin-top: 5px; }


%sideRail {
    margin-top: 25px;
    min-width: 260px;
    max-width: 275px; }

%railButton {
    @extend %horizontallyCentered; }

%primaryDarkButton {
    color: $primary-dark-color!important;
    background-color: rgba(0,0,0,0)!important;
    border-color: $primary-dark-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-dark-color!important;
        color: rgb(256,256,256)!important; } }

%primaryLightButton {
    color: $primary-light-color!important;
    background-color: rgba(255,255,255,1)!important;
    border-color: $primary-light-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-light-color!important;
        color: rgb(256,256,256)!important; } }

%secondaryDarktButton {
    color: white!important;
    border-color: white!important;
    background-color: $primary-dark-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-dark-color!important;
        color: rgb(256,256,256)!important; } }


.secondaryDarktButton {
    color: white!important;
    border-color: white!important;
    background-color: $primary-dark-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-dark-color!important;
        color: rgb(256,256,256)!important; } }

%secondaryLightButton {
    color: white!important;
    border-color: white!important;
    background-color: $primary-light-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-light-color!important;
        color: rgb(256,256,256)!important; } }

.primaryLightButton {
    color: $primary-light-color!important;
    background-color: rgba(255,255,255,1)!important;
    border-color: $primary-light-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-light-color!important;
        color: rgb(256,256,256)!important; } }

.secondaryLightButton {
    color: white!important;
    border-color: white!important;
    background-color: $primary-light-color!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    transition-property: color, background-color, border-color, box-shadow!important;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s!important;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out!important;
    transition-delay: 0s, 0s, 0s, 0s!important;

    &:hover {
        background-color: $primary-light-color!important;
        color: rgb(256,256,256)!important; } }

.mr-z {
    margin-right: 0px!important; }

.mr-auto {
    margin-right: auto!important; }

.mr-xs {
    margin-right: 3px!important; }

.mr-s {
    margin-right: 5px!important; }

.mr-m {
    margin-right: 10px!important; }

.mr-l {
    margin-right: 15px!important; }

.mr-xl {
    margin-right: 20px!important; }

.mr-xxl {
    margin-right: 25px!important; }

.ml-z {
    margin-left: 0px!important; }

.ml-xs {
    margin-left: 3px!important; }

.ml-s {
    margin-left: 5px!important; }

.ml-m {
    margin-left: 10px!important; }

.ml-l {
    margin-left: 15px!important; }

.ml-xl {
    margin-left: 20px!important; }

.ml-xxl {
    margin-left: 25px!important; }

.ml-auto {
    margin-left: auto!important;
    display: block; }

.mb-z {
    margin-bottom: 0px!important; }

.mb-xs {
    margin-bottom: 3px!important; }

.mb-s {
    margin-bottom: 5px!important; }

.mb-m {
    margin-bottom: 10px!important; }

.mb-l {
    margin-bottom: 15px!important; }

.mb-xl {
    margin-bottom: 20px!important; }

.mb-xxl {
    margin-bottom: 25px!important; }

.mb-auto {
    margin-bottom: auto!important; }

.mt-xs {
    margin-top: 3px!important; }

.mt-s {
    margin-top: 5px!important; }

.mt-m {
    margin-top: 10px!important; }

.mt-l {
    margin-top: 15px!important; }

.mt-xl {
    margin-top: 20px!important; }

.mt-xxl {
    margin-top: 25px!important; }

.pl-z {
    padding-left: 0px!important; }

.pl-s {
    padding-left: 5px!important; }

.pl-m {
    padding-left: 10px!important; }

.pl-l {
    padding-left: 15px!important; }

.pl-xl {
    padding-left: 20px!important; }

.pl-xxl {
    padding-left: 25px!important; }

.pr-z {
    padding-right: 0px!important; }

.pr-s {
    padding-right: 5px!important; }

.pr-m {
    padding-right: 10px!important; }

.pr-l {
    padding-right: 15px!important; }

.pr-xl {
    padding-right: 20px!important; }

.pr-xxl {
    padding-right: 25px!important; }

.pb-m {
    padding-bottom: 10px!important; }

.pb-l {
    padding-bottom: 15px!important; }

.pb-xl {
    padding-bottom: 20px!important; }

.pb-xxl {
    padding-bottom: 25px!important; }

.pt-z {
    padding-top: 0px!important; }

.pt-s {
    padding-top: 5px!important; }

.pt-m {
    padding-top: 10px!important; }

.pt-l {
    padding-top: 15px!important; }

.pt-xl {
    padding-top: 20px!important; }

.pt-xxl {
    padding-top: 25px!important; }

.zeroMargin {
    margin-left: 0px!important;
    margin-right: 0px!important; }

.zeroPadding {
    padding-left: 0px!important;
    padding-right: 0px!important; }

.horizontalDivider {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1px;
    border: 0.5px solid rgb(196, 196, 196); }

.alignRight {
    text-align: right; }

.alignCenter {
    text-align: center; }

.lightLabelContainer {
    color: $primary-light-color !important;
    background-color: white !important;
    border-color: $primary-light-color !important;
    border: 1px solid;
    line-height: 24px;
    border-radius: 4px;
    min-width: 55px;
    text-align: center;
    vertical-align: middle; }

.lightInputContainer {
    color: $primary-light-color !important;
    background-color: white !important;
    border-color: $primary-light-color!important;
    border: 1px solid;
    line-height: 24px;
    border-radius: 4px;
    min-width: 55px;
    text-align: center;
    vertical-align: middle;

    input {
        border: 0px!important;
        color: $primary-light-color!important;
        max-height: 26px!important; } }

.dangerInputContainer {
    color: $primary-light-color !important;
    background-color: white !important;
    border-color: red!important;
    border: 1px solid;
    line-height: 24px;
    border-radius: 4px;
    min-width: 55px;
    text-align: center;
    vertical-align: middle;

    input {
        border: 0px!important;
        color: red!important;
        max-height: 26px!important; } }

.bold {
    font-weight: bold; }

.title {
    font-size: $title-font-size;
    font-weight: bold; }

.subtitle {
    font-size: $subtitle-font-size;
    font-weight: bold; }

.fullWidth {
    width: 100%; }

.pointer {
    cursor: pointer; }

.btn {
    &:focus {
        outline: none!important;
        box-shadow: none !important; } }

.button {
    outline: none!important;
    box-shadow: none !important;
    &:focus {
        outline: none!important;
        box-shadow: none !important; } }

.svg {
    outline: none!important;
    box-shadow: none !important; }

.vCentered {
    margin-top: auto!important;
    margin-bottom: auto!important; }

.vCenteredParent {
    display: grid;
    align-items: center; }

.hCentered {
    margin-left: auto!important;
    margin-right: auto!important; }

.flex {
    display: flex; }

.block {
    display: block; }

.in-block {
    display: inline-block!important; }

.lightPrimary {
    color: $primary-light-color!important; }

.darkPrimary {
    color: $primary-dark-color; }

.darkPrimary {
    color: $primary-dark-color; }

.black {
    color: black; }

.fadedBackground {
    background-color: rgba(0, 0, 0, 0.40); }

.vCenteredFlex {
    display: inline-flex;
    align-items: center; }
