body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navBar {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-height: 53px!important;
    min-height: 50px;
    display: flex;
        &.navbar-light {
            @media screen and (min-width: 1240px)  {
                justify-content: center
            }
            @media (max-width: 1240px)  {
                justify-content: flex-start
            }
        }
}
// remove label on post input
.remove-label div fieldset legend{
  width: 0 !important;
}

div.modal {
  overflow: auto !important;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

