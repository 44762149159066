@import './BaseStyle.sass';

.mainNavContainer {
    min-width: 885px!important;
    max-width: 1228px!important;
    display: flex;
    flex-grow: 1;
    justify-content: space-between; }




// .navBar
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
//     max-height: 53px!important
//     display: flex
//     div
//     &.navbar-light
//         justify-content: flex-start



.navIcon {
  margin-left: auto!important;
  margin-right: auto!important; }

.navbar-brand {
  img {
    width: 40px!important;
    height: 40px!important; } }

.navProfileImage {
  width: 45px;
  height: 45px; }

.navbar-brand {
  margin-top: auto!important;
  margin-bottom: auto!important; }

.dropdown-menu {
  a {
    &.nav-link {
      height: 25px;
      padding-bottom: 0px!important;
      padding-top: 6px;
      margin-left: 5px!important;
      display: flex;
      margin-right: 5px!important; }
    &.nav-link::after {} } }

.navToggle {
  margin-left: 10px;
  display: flex;
  span {
    height: 25px;
    display: block;
    padding-left: 7px!important;
    padding-top: 6px!important;
    padding-bottom: 0px!important;
    margin-left: 5px!important;
    margin-right: 5px!important; } }

.navToggle:after {
  top: 10px;
  display: none!important;
  padding-top: 20px!important; }




.nav-link {
  height: 57px;
  padding-bottom: 0px!important;
  padding-top: 7px;
  margin-left: 5px!important;
  margin-right: 5px!important; }

.notificationBubble {
    position: absolute;
    min-width: 17px;
    top: -10px;
    text-align: center;
    right: -6px;
    padding: 1px 2px;
    border-radius: 50%;
    font-size: 12px;
    background-color: $primary-warm-color;
    color: white;
    font-weight: bold;
    line-height: 16px;
    height: 17px; }

.notificationBubbleSurveys {
    position: sticky;
    top: 75px;
    text-align: center;
    z-index: 1001;
    padding: 1px 5px;
    border-radius: 50%;
    font-size: 12px;
    background-color: #F05864;
    color: white;
    font-weight: bold;
    line-height: 16px;
    margin-left: 311px;
    height: 16px; }

.navIconRow {
  margin-top: 4px;
  margin-bottom: 4px; }

.navbar-nav {

  button {
      &:focus {
          outline: none!important;
          box-shadow: none !important; } } }
.nav-item {

  a {
    &.active {
      color: $primary-light-color!important;
      border-bottom: 3px solid $primary-light-color!important;
      svg {
        &.navIcon {
          color: $primary-light-color!important; } } } } }

.verticalSeparator {
  background-color: rgb(188, 188, 188);
  width: 1px;
  max-width: 1px;
  height: 100%;
  margin-left: 5px!important;
  margin-right: 5px!important; }

.button {
    &:focus {
        outline: none!important;
        box-shadow: none !important; } }
