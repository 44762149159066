@import './BaseStyle.sass';

.modal-content {
    display: flex;
    flex-direction: column; }

body {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    width: 100%;
    overflow: auto !important;
    padding-right: 0 !important; }

.wrapLink {
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto; }


.ui.popup {
    font-size: 12px!important;
    background-color: $primary-dark-color!important; }

.ui.popup:before {
    font-size: 12px!important;
    background-color: $primary-dark-color!important; }

.ui {
    &.toggle {
        &.checkbox {
            input:checked~label:before {
                background-color: $primary-dark-color!important; }
            input:focus:checked~label:before {
                background-color: $primary-dark-color!important; } } } }

.autoTextArea {
    padding-bottom: 5.25px;
    padding-top: 5.25px;
    padding-left: 10.5px;
    padding-right: 10.5px;
    line-height: 21px;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

//iframe
//    pointer-events: none
