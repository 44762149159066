.rail {
    margin-top: 25px;
    min-width: 260px;
    max-width: 260px;
    &.left {
        min-width: 260px;
        max-width: 260px
    }
}

.AppContainer {
    background-color: #F8F8F8;
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    justify-content: center;

    //Remove this to enable mobile
    @media (min-device-width: 1150px)  {
    }
}

.center {
    margin-top: 0;
    padding-right: 0;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
    min-width: 688px;
    display: flex;
    flex-direction: column;
    @media (max-device-width: 1150px)  {
        max-width: 100%;
        min-width: 100%;
        margin-top: 5px;
        margin-left: 0;
    }
}

.main {
    max-width: 1228px;
    padding-top: 50px;
    width: 100%;
    display: flex;

    @media (max-device-width: 1150px)  {
        width: 100vw;
        padding-top: 50px;
    }
}

.programsItemsRow {
    @media (max-device-width: 1150px)  {
        max-width: 100%;
        min-width: 100%;
        margin-top: 5px;
        justify-content: center;
    }
    width: 700px;
    margin-left: -15px!important;
    margin-right: 0!important
}


.taskMarketPlaceItemsRow {
    @media (max-device-width: 1150px)  {
        max-width: 100%;
        min-width: 100%;
        margin-top: 5px;
        justify-content: center;
    }
    width: 700px;
    margin-left: -15px!important;
    margin-right: 0!important
}


.catalogItemsRow {
    width: 700px;
    @media (max-device-width: 1150px)  {
        max-width: 100%;
        width: 100%;
        min-width: 100%;
        margin-top: 5px;
        justify-content: center;
        margin-left: 0!important;
        margin-right: 0!important;
    }
}

.catalogProductsRow {
    margin-right: auto;
    margin-left: 0;
    padding-left: 3px;
    width: 700px;

    @media (max-device-width: 1150px)  {
        max-width: 100%;
        width: 100%;
        min-width: 100%;
        margin-top: 5px;
        justify-content: center;
        padding-left: 0;
    }

}

.createTaskModalShowButton{
    @media (max-device-width: 1150px)  {
        margin-top: 20px;
        margin-left: 15px;
        justify-content: center;
    }
        cursor: pointer;
        color: #3a71b5;
        margin-left: auto!important;
        margin-top: 37px;
        margin-right: 25px;
}

.catalogDropdown {
    @media (max-device-width: 1150px)  {
        max-width: 180px;
        min-width: 1px!important
    }
}

.navSearch {
    @media (max-device-width: 1150px)  {
        flex-grow: 1;
        margin-left: 15px;
    }
  margin-top: auto;
  margin-bottom: auto
}
