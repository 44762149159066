.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #25255B;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader {
    width: 600px;
}

.text-animation-container{
    position: relative;
    color: white;
    overflow: hidden;
    width: 100%;
    height: 40px;
    margin-top: -40px;
    text-align: end;
    
}
.text-animation-container .text-container{
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    font-size: 1.6em;
    line-height: 40px;
    animation-name: animation;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    transition-timing-function: ease;
    padding: 40px;

}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
    .text-animation-container .text-container {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        font-size: 1.1em;
        line-height: 40px;
        animation-name: animation;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        transition-timing-function: ease;
        padding: 10px;
    }
}

@keyframes animation {
    0% {   top: 0; }
    10% {  top: 0; }

    15% {  top: -40px; }
    25% {  top: -40px; }

    30% {  top: -80px; }
    40% {  top: -80px; }

    45% {  top: -120px; }
    55% {  top: -120px; }

    60% {  top: -160px; }
    70% {  top: -160px; }

    75% {  top: -200px; }
    85% {  top: -200px; }

    90% {  top: -240px; }
    100% { top: -240px }
}